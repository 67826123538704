.bookmakersContainer {
    p {
        text-align: center;
    }

    table.bookmakersList {
        margin: auto;
        max-width: 90%;

        thead {
            text-transform: uppercase;
            font-family: $font-title;

            .flex {
                display: flex;

                .liveContainer {
                    color: $color-red;
                    display: flex;
                    align-items: center;

                    .liveIcon {
                        width: 15px;
                        height: 15px;
                        background-color: $color-red;
                        border-radius: 100%;
                        margin-left: 10px;
                        margin-right: 2px;
                    }
                }
            }
        }

        tbody {
            .book {
                font-weight: bold;
            }

            .mirors {
                font-size: 0.9rem;
            }

            .starsContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                color: $color-secondMain;
                font-size: 1.2rem;

                .nothing {
                    color: $color-red;
                }
            }
        }

        td,
        th {
            padding: 10px 50px;
            border: 1px solid $color-grey;
        }

        th {
            padding: 20px 50px;
        }
    }
}