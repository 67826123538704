#adminUsers {
	.filters {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		margin-bottom: 10px;

		.formField {
			display: flex;
			justify-content: flex-start;
			gap: 5px;
			margin: 0;

			label {
				margin: 0;
				width: fit-content;
			}

			input,
			select {
				font-size: 1.05rem;
				padding: 10px;
				margin: 0;
				text-align: center;
				font-family: $font-text;
				border-width: 0;
				border-radius: 0;
				background-color: $color-firstMain-light;
				color: $color-light;
			}

			select {
				width: 150px;
			}

			input {
				text-align: left;
			}
		}
	}

	.action-bar {
		display: flex;
		gap: 10px;
		margin-bottom: 10px;

		button {
			margin: 0;
			padding: 5px 10px;
		}
	}

	.datatable-actions {
		display: flex;
		gap: 10px;

		button {
			padding: 5px;
			border-radius: 20px;

			svg {
				font-size: 18px;
			}
		}
	}

	.popupContainer {
		.popup {
			width: 750px;

			.content {
				form {
					display: flex;
					flex-direction: column;
					gap: 20px;
					align-items: center;

					.formField {
						display: flex;
						flex-direction: column;
						gap: 5px;
						margin: 0;

						textarea {
							resize: none;
						}

						input,
						select {
							font-size: 1.05rem;
							padding: 10px;
							margin: 0;
							text-align: center;
							font-family: $font-text;
							border-width: 0;
							border-radius: 0;
							background-color: $color-firstMain-light;
							color: $color-light;
						}
					}
				}

                .notificationInfo {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    p {
                        text-align: center;
                        margin: 0;
                    }
                }
			}
		}
	}
}
