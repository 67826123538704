#CGV,
#ML {
    text-align: justify;
    padding: 0 10%;
    margin: auto;

    h3 {
      color: $color-secondMain;
      margin-top: 40px;
    }

    p {}
}

#CGV {}

#ML {
    p {
        text-align: center;
    }
}