#section-notification {
	position: relative;

	.notification-connection-status {
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		justify-content: center;
		width: 150px;
		padding: 5px;

		.status-indicator {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			margin-right: 8px;

			&.connected {
				background-color: $color-green;
			}

			&.disconnected {
				background-color: $color-red;
			}
		}

		.status-text {
			display: flex;
			flex-direction: column;

			p {
				margin: 0;
				font-size: 15px;
				color: $color-light-notification;
			}

			span {
				color: $color-yellow-notification;
				font-size: 15px;
			}
		}
	}

	.notifications-container {
		display: flex;
		flex-wrap: wrap;
		gap: 1.5rem;
		justify-content: start;

		.no-notifications {
			text-align: center;
			width: 100%;

			img {
				max-width: 350px;
				margin-bottom: 10px;
			}

			p {
				font-size: 1.4em;
				color: $color-lightDark;
				text-align: center;
			}
		}

		.motion-notification-card {
			display: flex;
		}

		.notification-card {
			width: 500px;
			background-color: $color-firstMain-light;
			display: flex;
			flex-direction: column;
			padding: 1rem;
			box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
			position: relative;
			gap: 15px;

			.svg {
				display: none;

				&.active {
					display: initial;
					position: absolute;
					top: 0;
					left: 0;

					.line {
						stroke-dasharray: 250;
						stroke-width: 1px;
						fill: transparent;
						stroke: rgba(230, 183, 46, 1);
						animation: svgAnimation 2.5s linear infinite;
					}

					@keyframes svgAnimation {
						from {
							stroke-dashoffset: 0;
						}
						to {
							stroke-dashoffset: 1000;
						}
					}
				}
			}

			&__top {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin: 0;

				&__title {
					font-size: 1.3rem;
					color: $color-darkyellow-notification;
					margin: 0;
					font-family: $font-title;
					display: flex;
					gap: 20px;
				}

				&__warning {
					color: $color-yellow-notification;
					font-size: 1rem;
					margin: 0;
					background-color: $color-main-notification;
					padding: 0.5rem;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
				}
			}

			&__details {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			&__matchInfo {
				span {
					font-size: 0.9rem;
					color: $color-light-notification;
				}
			}

			&__match {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				margin-bottom: 15px;

				span {
					font-size: 1.5rem;
					color: $color-light-notification;
					font-family: $font-title;

					&.versus {
						font-size: 0.9rem;
						color: $color-darkyellow-notification;
					}
				}
			}

			&__middle {
				box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
				background-color: $color-main-notification;
				padding: 1rem;
				margin: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				width: 80%;
				gap: 5px;

				p {
					margin: 0;
					color: $color-light-notification;

					&.details {
						font-size: 0.9rem;
					}

					&.info {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 25px;
						width: 100%;
					}

					label {
						font-size: 1.4rem;
						font-family: $font-title;
						color: $color-darkyellow-notification;
					}

					.betToPlace {
						font-size: 1.3rem;
						font-family: $font-title;
						color: $color-darkyellow-notification;
					}
				}

				.emoji {
					font-size: 1.5rem;
				}
			}

			h5 {
				width: 100%;
				text-align: center;
				margin: 0 0 5px 0;
				color: $color-darkyellow-notification;
			}

			&__bottom {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 0.5rem;
				overflow-x: auto;
				scroll-behavior: smooth;
				scrollbar-color: #65729c #252b46;
				padding: 0;
				z-index: 100;

				&.has-scroll {
					padding: 0 0 20px 0;
				}

				&__link {
					all: unset;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 0.5rem;
					min-width: 80px;
					text-align: center;
					background-color: $color-main-notification;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
					font-size: 1rem;
					color: $color-darkyellow-notification;

					text-decoration: none;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}

					&__book {
						font-family: $font-title;
						text-transform: capitalize;
					}

					&__text {
						font-size: 0.8rem;
						color: $color-light-notification;
					}
				}

				&__btn {
					all: unset;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border: 1px solid $color-darkyellow-notification;
					padding: 0.5rem;
					min-width: 80px;
					text-align: center;
					background-color: $color-firstMain-light;
					font-size: 1rem;
					color: $color-darkyellow-notification;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
					text-decoration: none;
					cursor: pointer;
					position: relative;
					overflow: hidden;

					&::before {
						content: "";
						position: absolute;
						top: -50%;
						left: -50%;
						width: 200%;
						height: 200%;
						background: linear-gradient(135deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.3) 100%);
						transform: translateX(-100%) rotate(30deg);
						animation: shine 1.5s infinite;
					}

					&.selected {
						background-color: darken($color-firstMain-light, 10%);
						transform: scale(1.05);
					}
				}

				@keyframes shine {
					0% {
						transform: translateX(-100%) translateY(-100%) rotate(30deg);
					}
					100% {
						transform: translateX(100%) translateY(100%) rotate(30deg);
					}
				}
			}

			&__footer {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 1rem;
				margin: 0;
				z-index: 100;

				button {
					flex: 1;
					padding: 0.5rem;
					border: none;
					font-size: 0.9rem;
					cursor: pointer;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
					color: $color-light-notification;

					&.btn-take {
						background-color: $color-green-notification;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 5px;
					}

					&.btn-not-taken {
						background-color: $color-orange-notification;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 5px;
					}

					&.btn-ban {
						background-color: $color-red-notification;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 5px;
					}

					&.btn-cancel {
						background-color: lightslategray;
						grid-column: 1 / -1;
						display: flex;
						justify-content: center;
						align-items: center;
						gap: 5px;
					}

					&.btn-bookmaker {
						background-color: $color-firstMain-light;
					}
				}
			}

			&__edit {
				margin: 1rem 0 0 0;
				z-index: 100;

				div {
					display: flex;
					gap: 5px;

					label {
						display: block;
						color: $color-light-notification;
						margin: 0 0 0.5rem 0;
						font-size: 0.9rem;
					}

					input,
					select {
						width: 100%;
						padding: 0.5rem;
						margin: 0 0 1rem 0;
						box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
						background-color: $color-main-notification;
						color: $color-light-notification;
					}
				}

				button {
					background-color: $color-green-notification;
					color: $color-light-notification;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
					font-size: 0.9rem;
					padding: 0.5rem 1rem;
					border: none;
					cursor: pointer;
					width: 100%;
					letter-spacing: 0.5px;
				}
			}

			&__success {
				color: $color-light-notification;
				font-size: 1rem;
				margin: 1rem 0 0 0;
				padding: 0 1rem 1rem 1rem;
				background-color: $color-main-notification;
				box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
				z-index: 100;

				.btn-edit {
					flex: 1;
					padding: 0.5rem;
					border: none;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
					font-size: 1rem;
					cursor: pointer;
					background-color: $color-yellow-notification;
					color: $color-firstMain;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;

					&:disabled {
						opacity: 0.5;
						cursor: not-allowed;
					}
				}

				.counter-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 2px;
					padding: 10px;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
					background-color: $color-firstMain-light;
					border: 1px solid $color-darkyellow-notification;
				}

				p {
					span {
						font-family: $font-title;
						text-align: center;
						font-size: 1rem;
						color: $color-darkyellow-notification;
					}
				}
			}

			&__banned {
				color: $color-light-notification;
				font-size: 1rem;
				margin: 1rem 0 0 0;
				padding: 0 1rem 1rem 1rem;
				background-color: $color-main-notification;
				box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

				.counter-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 2px;
					padding: 10px;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
					background-color: $color-firstMain-light;
					border: 1px solid $color-darkyellow-notification;

					span {
						font-size: 2rem;
						font-weight: bold;
						color: $color-darkyellow-notification;
					}
				}
			}

			&__success,
			&__banned {
				.counter-wrapper {
					span {
						font-size: 2rem;
						font-weight: bold;
						color: $color-darkyellow-notification;
					}

					.loader {
						width: 35px;
						height: 35px;
						margin: 10px 5px 5px 5px;
						border: 2px solid rgba(0, 0, 0, 0.1);
						border-top: 2px solid $color-darkyellow-notification;
						border-radius: 50%;
						animation: spin 1s linear infinite;
						display: inline-block;
					}

					@keyframes spin {
						0% {
							transform: rotate(0deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}
				}
			}

			&__not-taken {
				color: $color-light-notification;
				font-size: 1rem;
				margin: 1rem 0 0 0;
				padding: 0 1rem 1rem 1rem;
				background-color: $color-main-notification;
				box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

				.counter-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 2px;
					padding: 10px;
					box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
					background-color: $color-firstMain-light;
					border: 1px solid $color-darkyellow-notification;

					span {
						font-size: 2rem;
						font-weight: bold;
						color: $color-darkyellow-notification;
					}
				}
			}

			&__timer {
				p {
					padding: 0;
					font-size: 15px;
					text-align: center;
				}
			}
		}
	}
}

#notification-btn {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	flex-direction: column;
	background-color: $color-firstMain-light;
	color: white;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s ease-in-out, transform 0.1s;
	box-shadow: rgba(230, 183, 46, 0.4) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
	right: 25px;
	z-index: 999;
	outline: none;
	overflow: hidden;
	min-width: 250px;

	&:hover {
		background-color: $color-main-notification;
	}

	&::before {
		content: "";
		position: absolute;
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		background: linear-gradient(135deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.3) 100%);
		transform: translateX(-100%) rotate(120deg);
		animation: shine 2s infinite;
	}

	@keyframes shine {
		0% {
			transform: translateX(-100%) translateY(-100%) rotate(120deg);
		}
		100% {
			transform: translateX(100%) translateY(100%) rotate(120deg);
		}
	}

	.notification-btn-inside {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 15px;

		.emoji {
			font-size: 2.5rem;
		}

		.notification-btn-details {
			display: flex;
			flex-direction: column;
			gap: 5px;

			span {
				color: $color-light-notification;

				&.bet {
					font-family: $font-title;
					color: $color-darkyellow-notification;
					font-size: 1.2rem;
				}
			}

			p {
				margin: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
			}
		}
	}
}
