#pricing {
    h1 {
        font-size: 1.9rem;
        text-align: center;
        margin: 60px auto 30px auto;
    }

    .paymentMethods {
        margin: 70px 0 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 40px;

        img {
            width: 100px;
            filter: grayscale(1);
        }
    }
}