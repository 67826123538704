#contact {
    h1 {
        font-size: 1.9rem;
        text-align: center;
        margin: 60px auto 30px auto;
    }

    .contactMethodsContainer {
        display: flex;
        gap: 7%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;

        .contactMethodContainer {
            text-align: center;

            h2 {
                color: $color-secondMain;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                font-size: 1.2rem;
                margin-right: 10px;

                img {
                    width: 40px;
                }
            }

            .telegramLinksContainer {
                display: flex;
                flex-direction: column;
                align-items: center;

                .langContainer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    gap: 20px;

                    .lang {
                        img {
                            width: 30px;
                        }
                    }

                    .links {
                        .link {
                            margin: 10px 0;

                            a {
                                color: $color-light;
                            }
                        }
                    }
                }
            }
        }
    }
}