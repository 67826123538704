#FAQ {
    h1 {
        font-size: 1.9rem;
        text-align: center;
        margin: 60px auto 30px auto;
    }

    p {
        text-align: center;
        width : 60%;
        margin: 20px auto;
    }

    #items {
        width: 60%;
        margin: auto;

        // MOBILE
        @media (max-width: $width-mobile) {
            & {
                width: 80%;
            }
        }

        .item {
            padding: 10px;
            border-bottom: 1px solid $color-secondMain;
            cursor: pointer;

            &:hover {
                background-color: $color-firstMain-light;
            }
            
            .title {
                transition: .5s ease-in-out;
                font-size: 1.1rem;
                font-weight: bold;
                display: flex;
                align-items: center;
                gap: 20px;
                padding: 10px 0;

                svg {
                    font-size : 1.1rem;
                    color: $color-secondMain;
                }
            }

            .content {
                display: none;
                margin-top: 10px;
                margin-bottom: 20px;
                text-align: justify;

                .link {
                  cursor: pointer;

                  &:hover {
                    text-decoration: underline;
                  }
                }
            }

            &.opened {
                .title {
                    svg {
                        transform: rotate(90deg);
                    }
                }

                .content {
                    display: block;
                }
            }
        }

        #majorLeagues {
            .content {
                p {
                    text-align: left;
                    width: 90%;
                }
            }
      }
    }
}