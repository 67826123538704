#valueBetting {
    width: 70%;
    margin: auto;
    margin-top: 120px;

    // MOBILE
    @media (max-width: $width-mobile) {
        & {
            width: 90%;
        }
    }

    h2 {
        color: $color-secondMain;
        margin-top: 50px;
    }

    img {
        display: block;
        margin: auto;
        max-width: 100%
    }

    .contentContainer {
        display: flex;
        align-items: center;
        gap: 30px;

        // MOBILE
        @media (max-width: $width-littleMobile) {
            & {
                flex-direction: column;
            }
        }

        img {
            width: 300px;

            &#bookMargin {
                width: 400px;
            }
        }

        .content {
            text-align: justify;
        }
    }

    p {

        &.formula,
        &.danger {
            padding: 20px;
            text-align: center;
        }

        &.formula {
            border: 1px solid $color-grey;
            font-family: monospace;
            font-size: 0.8rem;
            margin: 20px auto;
            width: 70%;

            strong {
                &.odd1 {
                    color: $color-red;
                }

                &.odd2 {
                    color: $color-green;
                }
            }
        }

        &.danger {
            display: flex;
            gap: 30px;
            text-align: justify;
            align-items: center;
            padding: 0;

            img {
                width: 50px;
            }
        }
    }

    ul {
        li {
            margin: 10px 0;
        }
    }
}