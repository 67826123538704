.multiSelect {
    margin: 5px 1% 5px 0;
    width: 47%;
    position: relative;
    left: 3%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    label {
        font-weight: bold;
        margin-right: 10px;
        margin-left: 5px;

        .tooltipTrigger {
            margin-left: 5px;
            margin-right: 5px;
            font-size: 20px;
            position: relative;
            top: 3px;
        }
    }

    .multiSelect-values {
        background-color: $color-firstMain-light;
        position: relative;
        flex: 1;

        .values {
            padding: 3px;
            display: flex;
            flex-wrap: wrap;
            min-height: 40px;

            .book {
                background-color: $color-secondMain;
                padding: 4px;
                margin: 2px;
                font-size: 0.9rem;
                color: $color-firstMain;
                display: flex;
                align-items: center;
            }
        }

        .optionsContainer {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            background-color: $color-firstMain-light;
            padding: 10px 0;
            height: 100%;
            min-height: 400px;
            overflow-y: scroll;
            border-radius: 4px;
            font-size: 0.95rem;

            &.opened {
                display: block;
                z-index: 5;
            }

            .bookContainer {
                &:not(:first-of-type) {
                    margin-top: 10px;

                    .titleContainer {
                        border-top: 1px solid $color-secondMain;
                        border-bottom: 1px solid $color-secondMain;
                        padding: 10px;
                        margin-bottom: 5px;
                    }
                }

                .titleContainer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 10px;

                    .title {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 1rem;
                        color: $color-secondMain;
                    }

                    .buttons {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        .selectAll,
                        .unselectAll {
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .options {
                    display: flex;
                    flex-direction: column;
                    font-size: 1rem;

                    .option {
                        padding: 5px 0 5px 20px;
                        cursor: pointer;

                        &:hover {
                            background-color: $color-firstMain;
                        }

                        &.selected::before {
                            content: "\2714";
                            color: $color-secondMain;
                            margin-right: 15px;
                            font-size: 0.8rem;
                        }
                    }
                }
            }

        }
    }
}