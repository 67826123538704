#administration {
    .betContainer {
        border: 1px solid grey;
        margin: 20px;

        &.hidden {
            display: none;
        }

        h2 {
            margin: 0;
            padding: 8px 0;
            text-align: center;
            border-bottom: 1px solid lightgray;
            font-size: 1.5rem;

            svg {
                position: relative;
                top: 6px;
                right: 15px;
            }
        }

        h3 {
            margin: 5px 0;
            font-size: 1.2rem;
        }

        .formFieldsGroup {
            display: flex;
            flex-wrap: wrap;

            label.marketTitle {
                width: 100%;
                font-weight: bold;
                font-size: 1rem;
                margin-top: 15px;
            }
        }

        .formField {
            font-size: 1rem;
            margin: 3px 0;

            label {
                width: auto;
            }

            input,
            select {
                font-size: 0.9rem;
                padding: 3px;
                margin: 0;
                background-color: $color-firstMain-light;
                color: $color-light;
            }

            input {
                width: 70px;
            }

            select {
                width: auto;
            }
        }

        .splitView {
            display: flex;

            .closingsContainer,
            .resultsContainer {
                flex: 1;
                padding: 10px 20px;
                border-right: 1px solid lightgray;
            }

            .closingsContainer {}

            .resultsContainer {}

            .actionsContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 50px;
                gap: 15px;
                cursor: pointer;

                .surrender {
                    color: $color-red;

                    &:hover {
                        color: $color-red-dark;
                    }
                }

                .validate {
                    color: $color-green;

                    &:hover {
                        color: $color-green-dark;
                    }
                }
            }
        }
    }
}