$width-smallest : 820px;
$width-littleMobile : 1060px;
$width-mobile : 1200px;
$width-desktop : 1600px;

$width-lateralMenu : 100px;

$color-secondMain : #E6B72E;
$color-secondMain-dark : #d3a725;

$color-firstMain : #252B46;
$color-firstMain-light : #3a4266;
$color-firstMain-dark : #191F36;

$color-light : #EBE9FC;
$color-lightDark : #cdcbdf;
$color-grey: #a9a9a9;

$color-red : #cc2121;
$color-red-dark : #ac1818;
$color-green : #17a51e;
$color-green-dark : #108816;
$color-yellow : #faab36;
$color-yellow-dark : #e0993b;

$color-main-notification : #65729c;
$color-light-notification : #fbfcfe;
$color-darkyellow-notification : #e6b72e;
$color-yellow-notification : #f6d652;
$color-green-notification : #439137;
$color-orange-notification : #e28130;
$color-red-notification : #d13d2e;

@font-face {
    font-family: "font-title";
    src: url('./assets/fonts/FjallaOne-Regular.ttf');
}

@font-face {
    font-family: "font-text";
    src: url('./assets/fonts/Helvetica Neue Regular.otf');
}

$font-title : "font-title",
sans-serif;
$font-text : "font-text";