#home {
    h1 {
        font-size: 1.6rem;
        letter-spacing: 2px;
        margin-bottom: 0;

        &:not(.mainTitle) {
            margin-top: 0 !important;
        }

        &.mainTitle {
            font-size: 1.8rem;

            .space {
                padding-left: 142px;
            }
        }
    }

    h2 {
        font-size: 1rem;
        color: $color-grey;
        text-transform: uppercase;
        text-align: center;
        font-weight: normal;
    }

    hr {
        margin: 60px auto;
        width: 50%;
        border: 1px dashed $color-secondMain;
        opacity: 0.5;
    }

    section#strengthsSection {
        #strengths {
            text-align: center;
            position: relative;
            margin-bottom: 40px;

            #trophyContainer {
                width: 980px;
                margin: auto;
                position: relative;
                text-align: center;
                background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, transparent 30%, transparent 100%);

                #trophy {
                    width: 475px;
                    margin-top: 30px;
                }

                .strength {
                    position: absolute;
                    width: 280px;
                    text-align: center;

                    img {
                        width: 40px;
                    }

                    .title {
                        font-size: 0.9rem;
                        letter-spacing: 1px;
                        margin: 5px 0;
                        text-transform: uppercase;
                    }

                    .content {
                        font-size: 0.9rem;
                        margin: 0;
                        text-align: center;
                    }

                    &#first {
                        top: 10px;
                    }

                    &#second {
                        top: 150px;
                    }

                    &#third {
                        top: 290px;
                    }

                    &#fourth {
                        top: 450px;
                    }

                    &#fifth {
                        top: 10px;
                        right: 0;
                    }

                    &#sixth {
                        top: 165px;
                        right: 0;
                    }

                    &#seventh {
                        top: 310px;
                        right: 0;
                    }

                    &#eighth {
                        top: 450px;
                        right: 0;
                    }

                }
            }
        }

        #generateFirstProfitsContainer {
            text-align: center;

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                font-size: 0.8rem;
                font-weight: bold;
                text-transform: uppercase;
                margin: 0;
                margin-top: 7px;
                letter-spacing: 1px;

                img {
                    width: 20px;
                }
            }
        }
    }

    section#servicesSection {
        #services {
            margin: 50px 0;
            display: flex;
            flex-direction: column;
            gap: 50px;

            .service {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;
                margin: auto;
                width: 900px;

                .nbBetsContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border: 2px solid $color-secondMain;

                    img {
                        width: 40px;
                        margin: 5px 0;
                    }

                    p {
                        text-align: center;
                        background-color: $color-secondMain;
                        color: $color-firstMain;
                        font-weight: bold;
                        padding: 5px;
                        margin: 0;

                        .nbBets {
                            font-size: 1.4rem;
                            font-family: "font-title";
                        }

                        .label {
                            font-size: 0.6rem;
                            text-transform: uppercase;
                        }
                    }
                }

                .serviceDetailsContainer {
                    flex: 1;

                    .titlesContainer {
                        display: flex;
                        align-items: center;
                        gap: 15px;

                        .number {
                            font-size: 3.5rem;
                            color: $color-secondMain;
                            font-weight: bold;
                            font-family: "font-title";
                        }

                        .titles {
                            h2 {
                                color: $color-secondMain;
                                margin: 0;
                                text-align: left;
                                font-size: 1.5rem;
                            }

                            h3 {
                                margin: 0;
                                text-align: left;
                                text-transform: uppercase;
                                font-size: 1.2rem;
                            }
                        }
                    }

                    .description {
                        margin: 10px 0;
                        font-size: 1rem;
                    }
                }

                .prosConsContainer {
                    border-left: 2px solid $color-secondMain;
                    padding-left: 30px;
                    width: 300px;

                    .prosContainer,
                    .consContainer {
                        display: flex;
                        align-items: center;

                        img {
                            width: 30px;
                        }

                        ul {
                            list-style-type: none;
                            padding-left: 15px;
                            margin: 10px 0;

                            li {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }
    }

    section#clientsOpinionsSection {
        p {
            text-align: center;
        }

        .trustContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;
            margin-top: 50px;

            .trustPilotContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                font-family: $font-title;

                .label {
                    text-transform: uppercase;
                    text-align: center;

                    img {
                        margin-right: 5px;
                    }
                }

                .noteContainer {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    img {
                        width: 40px;
                        margin-right: 5px;
                    }

                    .currentNote,
                    .maxNote {
                        color: $color-secondMain;
                        font-weight: bold;
                    }

                    .currentNote {
                        font-size: 2.5rem;
                    }

                    .maxNote {
                        font-size: 1.2rem;
                        margin-top: 15px;
                    }
                }
            }

            .socialNetworksContainer {
                display: flex;
                gap: 10px;

                .socialNetwork {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    width: 110px;
                    text-align: center;
                    cursor: pointer;
                    padding: 10px 20px;

                    &:hover {
                        background-color: $color-firstMain-dark;
                    }

                    img {
                        width: 40px;
                    }
                }
            }
        }

        hr {
            width: 40%;
            background-color: $color-secondMain;
            height: 2px;
            margin: 30px auto;
        }

        .bestCommentsContainer {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 30px;
            width: 90%;
            margin: auto;

            .bestComment {
                background-color: $color-firstMain-light;
                width: 30%;

                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px;
                    border-bottom: 1px solid $color-secondMain;
                    font-family: $font-title;

                    .usernameDate {
                        display: flex;
                        flex-direction: column;

                        .username {
                            font-size: 1.1rem;
                        }

                        .date {
                            color: $color-grey;
                            font-size: 0.8rem;
                        }

                    }

                    .notation {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        img {
                            width: 30px;
                            margin-right: 10px;
                        }

                        .currentNote,
                        .maxNote {
                            color: $color-secondMain;
                            font-weight: bold;
                        }

                        .currentNote {
                            font-size: 2rem;
                        }

                        .maxNote {
                            font-size: 0.9rem;
                            padding-top: 10px;
                        }
                    }
                }

                .contentContainer {
                    padding: 15px;

                    .title {
                        font-size: 1.1rem;
                        margin: 0 0 10px 0;
                    }

                    .content {
                        margin: 0;
                        font-size: 1rem;
                        text-align: justify;
                    }
                }
            }
        }
    }

    section#benefitsSimulatorSection {
        #keyParametersContainer {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            justify-content: center;
            align-items: center;

            .keyParameter {
                display: flex;
                align-items: center;
                gap: 40px;

                img {
                    width: 60px;
                }

                .parameter {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .title {
                        font-family: "font-title";
                        font-size: 1.3rem;
                        text-transform: uppercase;
                    }

                    .sliderContainer {
                        display: flex;
                        gap: 10px;

                        .start,
                        .end {
                            width: 80px;
                            font-size: 1rem;
                            text-align: center;
                        }

                        input {
                            width: 400px;

                            /* removing default appearance */
                            -webkit-appearance: none;
                            appearance: none;
                            /* creating a custom design */
                            cursor: pointer;
                            height: 20px;
                            outline: none;
                            /*  slider progress trick  */
                            overflow: hidden;
                            border-radius: 15px;

                            /* Track: webkit browsers */
                            &::-webkit-slider-runnable-track {
                                height: 100%;
                                background: $color-grey;
                            }

                            /* Track: Mozilla Firefox */
                            &::-moz-range-track {
                                height: 100%;
                                background: $color-grey;
                            }

                            /* Thumb: webkit */
                            &::-webkit-slider-thumb {
                                /* removing default appearance */
                                -webkit-appearance: none;
                                appearance: none;
                                /* creating a custom design */
                                height: 100%;
                                width: 20px;
                                background-color: $color-firstMain;
                                border-radius: 50%;
                                border: 2px solid $color-secondMain;
                                /*  slider progress trick  */
                                box-shadow: -407px 0 0 400px $color-secondMain;
                            }


                            /* Thumb: Firefox */
                            &::-moz-range-thumb {
                                height: 15px;
                                width: 15px;
                                background-color: $color-firstMain;
                                border-radius: 50%;
                                border: 1px solid $color-secondMain;
                                /*  slider progress trick  */
                                box-shadow: -407px 0 0 400px $color-secondMain;
                            }
                        }
                    }
                }
            }
        }

        .benefitsResults {
            font-size: 1.5rem;
            text-align: center;
            font-family: "font-title";
            margin-top: 70px;
            margin-bottom: 10px;
        }

        .benefitsAlert {
            margin-top: 0;
            font-size: 1rem;
            width: 700px;
            margin: auto;
            text-align: center;
        }
    }

    section#nextStepsSection {
        .itemsContainer {
            margin-top: 20px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 50px;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 250px;

                p {
                    text-align: center;
                }

                button {
                    width: 250px;
                    padding: 10px 15px;
                }
            }
        }
    }
}