#login,
#register {
    flex: 1;

    .noAccount,
    .alreadyHaveAccount {
        font-size: 1.1rem;
        margin-top: 40px;
        text-align: center;

        strong:hover {
            color: $color-secondMain-dark;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .noAccount {
        margin-top: 140px;
    }

    h1 {
        margin-bottom: 20px;
        font-size: 1.9rem;
    }

    h2 {
        width: 50%;
        margin: 50px auto -20px auto !important;
        text-align: center;

        // MOBILE
        @media (max-width: $width-mobile) {
            & {
                width: 80%;
            }
        }

        hr {
            background-color: $color-secondMain;
            height: 3px;
        }

        span {
            font-size: 1.3rem;
            background-color: $color-firstMain;
            position: relative;
            bottom: 33px;
            z-index: 0;
            padding: 0 30px;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: bold;
        }
    }

    form {
        width: 50%;
        margin: auto;

        // MOBILE
        @media (max-width: $width-mobile) {
            & {
                width: 80%;
            }
        }

        .formContent {
            display: flex;
            align-items: flex-start;
            gap: 5%;

            .rightSide {
                padding-top: 30px;
                flex: 1;

                .formField {
                    &:first-of-type {
                        margin-top: 0;
                    }
                }

                input,
                select,
                textarea {
                    width: 100%;
                }

                .legals {
                    text-align: center;
                }

                .formError {
                    color: $color-red;
                    text-align: center;
                    list-style-type: none;
                }

                button {
                    margin-top: 30px;
                    padding-left: 50px;
                    padding-right: 50px;
                }
            }

            .leftPart {
                flex: 1;
                text-align: right;
                position: relative;

                img {
                    height: 300px;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}

#register {
    form .leftPart img {
        height: 480px !important;
        opacity: 40%;
    }

    form .rightSide {
        padding-top: 10px !important;
    }
}