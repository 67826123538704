#dashboard {
    h2 {
        margin-bottom: 20px;

        input {
            padding: 10px;
            position: relative;
            bottom: 5px;
            margin: 0 5px;
            font-family: $font-text;
            font-size: 1rem;
            border-width: 0;
            background-color: $color-firstMain-light;
            color: $color-light;
            color-scheme: dark;
        }

        .btn {
            margin: 0 0 0 40px;
            font-weight: bold;
            display: inline-block;
            position: relative;
            bottom: 4px;
        }
    }

    .filters {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border: 1px solid $color-light;

        legend {
            font-size: 1.5rem;
            font-weight: bold;
            font-family: $font-title;
            color: $color-secondMain;
            padding: 0 20px;
            margin-left: 20px;
        }

        h2 {
            width: fit-content;
            margin: 0;
            margin-right: 5px;
        }

        .fullWidth {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .formField {
                flex: 1;
                justify-content: flex-start;

                &:first-of-type {
                    justify-content: flex-end;
                }
            }
        }

        .formField {
            margin: 0;
            width: calc(33% - 15px);

            #sportsFilter,
            #marketsFilter {
                min-width: 200px;
            }

            label {
                margin: 0 5px;
                width: auto;
            }
        }

        // MOBILE
        @media (max-width: $width-mobile) {
            .formField {
                width: calc(50% - 15px);
            }

            .fullWidth {
                flex-wrap: wrap;

                .formField {
                    width: 100%;
                }
            }
        }
    }

    .keyFigures {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;

        .keyFigure {
            flex: 1;
            max-width: 250px;
            min-width: 200px;
            background-color: $color-secondMain;
            color: white;
            font-family: $font-title;
            position: relative;
            box-shadow: 1px 1px 16px 1px #00000057;
            transition: all 0.5s;

            &:nth-child(1) {
                background-color: #fd5901;
            }

            &:nth-child(2) {
                background-color: #f78104;
            }

            &:nth-child(3) {
                background-color: #faab36;
            }

            &:nth-child(4) {
                background-color: #249ea0;
            }

            &:nth-child(5) {
                background-color: #008083;
            }

            &:nth-child(6) {
                background-color: #005f60;
            }

            &:hover {
                box-shadow: 1px 1px 16px 1px #0000009f;
                filter: brightness(0.9);
            }

            .container {
                display: flex;
                gap: 10px;
                justify-content: space-around;
                align-items: center;
                height: 100%;
                padding: 20px 5%;
                text-transform: uppercase;
                flex-direction: column;

                div {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .value {
                    font-size: 3rem;
                    font-weight: bold;

                    small {
                        font-size: 2rem;
                    }
                }

                .label {
                    flex: 1;
                    font-size: 1.7rem;

                    small {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    .bottomPageContainer {
        display: flex;
        flex-direction: row;
        margin-top: 30px;

        // < MOBILE
        @media (max-width: $width-desktop) {
            & {
                flex-direction: column;
            }
        }

        .chartsContainer {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            flex: 1;
            margin: 0 20px;

            .pieChartsContainer {
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: space-evenly;
                gap: 50px;
                margin-top: 40px;

                .booksChart,
                .sportsRepartitionChart {
                    flex: 1;
                }

                .booksChart {}

                .sportsRepartitionChart {}
            }

            .graphsContainer {}
        }

        .datatableContainer {
            flex: 1;

            // < MOBILE
            @media (max-width: $width-desktop) {
                & {
                    margin-top: 40px;
                }
            }

            h2 {
                margin-left: 0;
                margin-top: 0;
                margin-bottom: 10px;
            }

            .rdt_Table {
                .rdt_TableHeadRow {
                    .rdt_TableCol {

                        &:nth-child(1),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            flex-grow: 0.2;
                        }

                        &:nth-child(2) {
                            flex-grow: 1.3;
                        }

                        &:nth-child(3) {
                            flex-grow: 1.3;
                        }
                    }
                }

                .rdt_TableRow {
                    .rdt_TableCell {
                        &:not(:nth-child(2)) {
                            text-align: center;
                        }

                        // Sport column
                        &:nth-child(1),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            flex-grow: 0.2;

                            i {
                                color: $color-secondMain;
                            }
                        }

                        // Match column
                        &:nth-child(2) {
                            flex-grow: 1.3;
                            justify-content: flex-start;
                        }

                        // Bet side column
                        &:nth-child(3) {
                            flex-grow: 1.3;
                        }

                        .oddContainer {
                            display: flex;
                            flex-direction: column;

                            .odd {}

                            .CLV {}
                        }

                        .betContainer {
                            display: flex;
                            flex-direction: column;

                            .iconAndText {
                                svg {
                                    font-size: 20px;
                                    color: $color-light;
                                    position: relative;
                                    float: left;
                                    bottom: 1px;
                                    margin-right: 5px;
                                }

                                span {}
                            }

                            .matchResult {
                                font-weight: bold;
                            }
                        }
                    }

                    &.notTaken {
                        background-color: #0000002f;
                    }
                }
            }
        }
    }
}