.formField {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    align-items: center;

    $standardLabelWidth : 200px;
    $standardInputWidth : 300px;

    label {
        width: $standardLabelWidth;
        font-weight: bold;
        margin-right: 10px;
        margin-left: 5px;

        .tooltipTrigger {
            margin-left: 5px;
            margin-right: 5px;
            font-size: 20px;
            position: relative;
            top: 3px;
        }
    }

    &.multiple {
        span {
            display: flex;
            align-items: center;
        }

        label:not(:nth-child(1)) {
            width: auto !important;
            margin-left: 10px;
        }
    }

    &.dhms {
        input[type=number] {
            width: 70px !important;
        }
    }

    input,
    select,
    textarea,
    #FOCalculationMethod,
    #valuePriorizationMethod,
    #sameEventValuesTreatment,
    #betMethod,
    #oddReference,
    #presetModel,
    #bookName {
        width: $standardInputWidth;
        font-size: 1.05rem;
        padding: 10px;
        margin: 0 5px;
        text-align: center;
        font-family: $font-text;
        border-width: 0;
        border-radius: 0;
        background-color: $color-firstMain-light;
        color: $color-light;

        &:disabled {
            background-color: $color-firstMain-dark;
        }
    }

    #excludedSports,
    #excludedSoftBooks,
    #excludedMarkets,
    #condition_markets,
    #condition_sports,
    #condition_books,
    #marketsFilter,
    #sportsFilter,
    #condition_markets,
    #booksFilter {
        width: $standardInputWidth;
        font-size: 1.05rem;
        padding: 10px;
        margin: 0 5px;
        text-align: center;
        font-family: $font-text;

        div[class$="-control"] {
            border-width: 0;
            background-color: $color-firstMain-light;
            border-radius: 0;

            div[class$="-multiValue"] {
                background-color: $color-secondMain;
                border-radius: 0;
                color: $color-firstMain;

                div[role="button"] {
                    cursor: pointer;

                    svg {
                        fill: $color-firstMain;
                    }

                    &:hover {
                        background-color: #df411b;

                        svg {
                            fill: $color-light;
                        }
                    }
                }
            }
        }

        div[class$="-menu"] {
            background-color: $color-firstMain-light;
            margin-top: 0;

            div[class$="-group"] {
                color: $color-secondMain;
                font-size: 1.1rem;
                font-weight: bold;
                text-align: left;
            }

            div[class$="-option"] {
                text-align: left;
                font-size: 0.9rem;
            }

            /* NOT selected option */
            div[class$="uf-option"] {}

            /* selected option */
            div[class$="ni-option"] {
                background-color: $color-secondMain;
                color: $color-firstMain;
                cursor: pointer;
            }
        }
    }

    input {
        &[type=number] {
            width: 100px;
        }

        &[type=date] {
            width: 140px;
            color-scheme: dark;
        }

        &[type=checkbox] {
            width: 20px;
            height: 20px;
            border-width: 0;
            accent-color: $color-secondMain;
        }
    }

    textarea {
        text-align: justify;
    }

    select {
        background-color: white;
    }

    &.thematized {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        label {
            color: $color-secondMain;
        }

        input,
        select,
        textarea {
            text-align: left;
        }
    }
}