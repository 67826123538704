#subscription {
    .alert {
        text-align: center;
        width: 70%;
        margin: auto;
        margin-bottom: 20px;
    }

    .pricingGroupsContainer {
        margin-bottom: 50px;
    }
}

#paymentSuccess,
#paymentFailed {
    text-align: center;
    width: fit-content;
    margin: auto;
    padding: 20px 40px;

    h1 {
        font-size: 2rem;
        text-transform: uppercase;
    }

    p {
        font-size: 1.3rem;
        text-align: center;
    }

    &#paymentSuccess {
        h1 {
            color: $color-green;
        }
    }

    &#paymentFailed {
        h1 {
            color: $color-secondMain;
        }
    }
}