.custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.custom-alert-box {
    background: $color-firstMain;
    padding: 20px;
    width: 25%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid $color-secondMain-dark;
}

.custom-alert-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: $color-light;
}

.custom-alert-message {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    color: $color-light;
}

.custom-alert-buttons button {
    margin: 0 5px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.custom-alert-confirm {
    background-color: $color-secondMain-dark;
    color: $color-light;
}

.custom-alert-cancel {
    background-color: $color-grey;
    color: $color-firstMain-dark;
}
