#botParameters,
#presetConfig {
    .backToPresets {
        margin-left: 5%;
    }

    h4 {
        font-size: 1.5rem;
    }

    h6 {
        font-size: 1rem;
        margin: 20px;
    }

    .alert {
        width: 80%;
        margin-right: auto;
        margin-left: auto;
        color: $color-secondMain;
        text-align: center;
    }

    fieldset {
        border: 1px solid $color-light;
        width: 90%;
        margin: 20px auto;
        padding: 20px;

        &#general {
            .formField {
                margin: 15px 0;

                input:not([type=checkbox]),
                textarea {
                    width: 70%;
                }
            }


        }

        &#filtersParams,
        &#conditions {
            display: flex;
            flex-wrap: wrap;

            .formField {
                margin: 5px 1% 5px 0;
                width: 49%;
                position: relative;
                left: 3%;
                justify-content: flex-start;

                // MOBILE
                @media (max-width: $width-mobile) {
                    & {
                        width: 100%;
                        left: 0;
                    }
                }

                label {
                    width: auto;
                    max-width: 300px;
                }

                input[type=number] {
                    width: 100px;
                }

                &.fullWidth {
                    width: 100%;
                    left: 0;
                    display: flex;
                    justify-content: center;

                    label {
                        max-width: fit-content;
                    }
                }
            }
        }

        &#moneyParams {
            display: flex;
            flex-direction: column;
            align-items: center;

            .betMethodContainer {
                &.hidden {
                    display: none;
                }
            }

            table {
                margin: 20px auto;

                &#kellyTable {
                    font-size: 0.9rem;

                    th {
                        font-size: 1.1rem;
                    }
                }

                thead {}

                tbody {
                    td {
                        &:nth-child(1) {
                            font-weight: bold;
                        }

                        input {
                            width: 60px;
                            text-align: center;
                            font-family: $font-text;
                            border-width: 0;
                            background-color: $color-firstMain-light;
                            color: $color-light;
                        }
                    }
                }

                th:nth-child(1),
                td:nth-child(1) {
                    min-width: 120px;
                }

                input {
                    text-align: center;
                }
            }
        }

        legend {
            margin-left: 40px;
            font-size: 1.5rem;
            padding: 0 20px;
            font-family: $font-title;
            color: $color-secondMain;
            text-transform: uppercase;
            font-weight: bold;
        }

        .formField {
            margin: 5px 0;

            label {
                width: auto;
            }
        }
    }

    .buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        button {
            margin: 0;
            margin-bottom: 30px;
        }
    }
}