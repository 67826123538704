#accountVerification {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;

    h1 {
        font-size: 3rem;

        &.success {
            color: $color-green;
        }
    
        &.failed {
            color: $color-red;
        }
    }

}