#presetsManagement {
    .alert {
        display: flex;
        gap: 20px;
        width: 80%;
        margin: 0 auto 30px auto;
        align-items: center;
        border: 1px solid silver;
        padding: 20px;

        svg {
            font-size: 50px;
            color: $color-secondMain
        }
    }

    #subscriptionsContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        gap: 50px;
        margin-top: 50px;

        .subscriptionPresets {
            border: 1px solid $color-light;
            min-width: 430px;

            h2.subscriptionName {
                font-family: $font-title;
                text-transform: uppercase;
                letter-spacing: 2px;
                color: $color-firstMain;
                background-color: $color-secondMain;
                text-align: center;
                margin: 0;
                padding: 20px 0;
                position: relative;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: $color-secondMain-dark;
                }

                svg {
                    position: absolute;
                    right: 20px;
                    font-size: 25px;

                    &.rotated {
                        transform: rotate(45deg);
                    }
                }
            }

            .presets {
                display: flex;

                &.reduced {
                    flex-direction: column;
                    align-items: stretch;

                    .preset {
                        border-bottom: 1px solid $color-grey;
                        padding: 10px 20px;
                        margin: 0;
                        position: relative;
                        display: flex;
                        align-items: center;

                        &:hover {
                            background-color: $color-firstMain-light;
                        }

                        .clickable {
                            cursor: pointer;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                        }

                        .byDefault {
                            color: gold;
                            margin-right: 10px;
                        }

                        .remove {
                            font-size: 25px;
                            cursor: pointer;
                            position: absolute;
                            right: 15px;
                            color: $color-red;

                            &:hover {
                                color: $color-red-dark;
                            }
                        }
                    }
                }

                &:not(.reduced) {
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 2%;
                    flex-wrap: wrap;
                    padding: 20px 30px 0 30px;

                    .preset {
                        border: 1px solid $color-grey;
                        width: 32%;
                        min-width: 300px;
                        background-color: $color-firstMain-light;
                        margin-bottom: 30px;

                        .title {
                            padding: 10px 5px;
                            margin: 0;
                            text-align: center;
                            color: $color-secondMain;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            .byDefault {
                                color: gold;
                                margin-right: 10px;
                            }

                            .changePriority {
                                position: absolute;
                                left: 15px;
                                display: flex;
                                gap: 5px;

                                svg {
                                    cursor: pointer;

                                    &:hover {
                                        color: $color-secondMain-dark;
                                    }
                                }
                            }

                            .remove {
                                cursor: pointer;
                                position: absolute;
                                right: 15px;

                                &:hover {
                                    color: $color-secondMain-dark;
                                }
                            }
                        }

                        .description {
                            padding: 10px;
                            border-top: 1px solid #c0c0c0;
                            border-bottom: 1px solid #c0c0c0;
                            margin: 0;
                            text-align: center;

                            i {
                                color: #9b9b9b;
                            }
                        }

                        .subTitle {
                            text-align: left;
                            margin: 10px;
                            font-weight: normal;
                        }

                        ul.conditions {
                            list-style-type: '+  ';
                            margin: 0;
                            margin-left: 40px;
                            margin-bottom: 20px;
                            padding: 0;

                            li {}
                        }

                        p {
                            margin: 10px;
                        }

                        a {
                            &:hover {
                                text-decoration: none;
                            }
                        }

                        button {
                            margin: 0;
                            width: 100%;
                            padding: 15px 0;
                            border-radius: 0;
                            border-width: 0;
                            border-top-width: 1px;
                        }

                        &.new {
                            border: 1px solid #c0c0c0;

                            .title {
                                border-bottom: 1px solid #c0c0c0;
                            }

                            .formField {
                                label {
                                    width: auto;
                                }
                            }

                            #presetModel {
                                background-color: $color-firstMain;
                            }
                        }
                    }
                }
            }

            #noRobotContainer {
                padding: 10px 20px;

                ul {
                    list-style-type: circle;

                    li {
                        margin: 15px 0;
                    }
                }

                .inputWithCopyButton {
                    position: relative;
                    width: 90%;
                    margin: auto;

                    input {
                        width: 100%;
                        padding: 10px;
                        text-align: center;
                        font-size: 1.3rem;
                        border-radius: 7px;
                        margin-top: 10px;
                        font-family: $font-text;
                        background-color: $color-firstMain-light;
                        color: $color-secondMain;
                    }

                    svg {
                        position: absolute;
                        right: 10px;
                        top: 15px;
                        color: $color-secondMain;
                    }
                }
            }
        }
    }
}