#profile {
    fieldset {
        border: 1px solid $color-light;
        width: fit-content;
        margin: 30px auto;
        padding: 0 80px;

        legend {
            color: $color-secondMain;
            font-weight: bold;
            font-size: 1.7rem;
            text-align: center;
            padding: 0 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        .formError {
            color: $color-secondMain;
            text-align: center;
        }

        .btn.thematized {
            margin-bottom: 10px;
        }
    }

    .popupContainer {
        .formError {
            color: $color-secondMain;
            text-align: center;
        }
    }
}