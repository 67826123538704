.popupContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000077;
    display: none;
    z-index: 9999;

    &.opened {
        display: block;
    }

    .popup {
        width: 500px;
        background-color: $color-firstMain;
        position: absolute;
        top: 10vh;
        left: calc(50% - 250px);

        .closeContainer {
            position: absolute;
            top: 15px;
            right: 20px;
            color: $color-light;
            font-weight: bold;
            font-size: 30px;
            cursor: pointer;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;

            &:hover {
                background-color: #c91515a2;
                transition: 0.3s;
            }
        }

        .title {
            background-color: $color-firstMain-light;
            color: $color-secondMain;
            margin: 0;
            padding: 15px 0;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .content {
            color: $color-light;
            padding: 10px 20px;

            .formField {
                label {
                    width: auto;
                }
            }
        }

        .actions {
            display: flex;
            justify-content: center;
            gap: 20px;
            background-color: $color-firstMain-light;
            padding: 10px 0;

            button {
                margin: 0;
                padding: 10px 20px;
                font-size: 1.1rem;
            }
        }
    }
}