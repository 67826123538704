.pricingGroupsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5%;

    .pricingGroupContainer {
        h2 {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            hr {
                position: absolute;
                top: 6px;
                width: 100%;
                height: 2px;
                background-color: $color-secondMain;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                gap: 15px;
                background-color: $color-firstMain;
                padding: 0 20px;
                z-index: 0;
                position: relative;

                img {
                    width: 40px;
                }
            }
        }

        .plansContainer {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 20px;

            .planContainer {
                border: 1px solid $color-secondMain;

                .header {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    padding: 20px 0;

                    h3 {
                        margin: 0;
                        font-size: 1.4rem;
                        color: $color-light;
                        text-transform: uppercase;
                        display: flex;
                        gap: 6px;

                        .liveContainer {
                            color: $color-red;
                            display: flex;
                            align-items: center;
                            gap: 2px;

                            .live {
                                width: 15px;
                                height: 15px;
                                background-color: $color-red;
                                display: block;
                                border-radius: 100%;
                            }
                        }
                    }

                    h4 {
                        margin: 0;
                        text-transform: uppercase;
                        color: $color-secondMain;
                        font-size: 2rem;

                        small {
                            font-size: 1rem;
                        }
                    }
                }

                .slogan {
                    background-color: $color-secondMain;
                    color: $color-firstMain;
                    padding: 10px 0;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 1rem;
                    font-family: 'font-title';
                }

                .servicesContainer {
                    display: flex;
                    height: 235px;
                    align-items: center;
                    font-size: 1rem;

                    ul.servicesList {
                        margin: 0;
                        padding-left: 30px;
                        padding-right: 10px;
                        list-style-type: '\2713';

                        li {
                            margin: 20px 0;
                            padding-left: 10px;

                            &::marker {
                                color: $color-secondMain;
                                font-size: 1.3rem;
                            }

                            small {
                                font-size: 0.8rem;
                            }
                        }
                    }

                    .subscriptionInfoContainer {
                        padding: 20px 20px;
                        list-style-type: none;

                        li {
                            margin: 15px 0;

                            b {
                                color: $color-secondMain;
                            }
                        }
                    }
                }

                button {
                    width: 100%;
                    height: 70px;
                    flex-direction: column;
                    gap: 0;
                    font-size: 1.2rem;

                    small {
                        font-size: 0.8rem;
                    }
                }

                .incomingContainer {
                    display: none;
                }

                &.incoming {
                    position: relative;

                    .incomingContainer {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 100;

                        .content {
                            font-size: 2rem;
                            background-color: $color-secondMain;
                            color: $color-firstMain;
                            padding: 20px 70px;
                            text-transform: uppercase;
                            font-family: $font-title;
                            rotate: 345deg;
                            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.388);
                        }
                    }

                    .header,
                    .slogan,
                    .servicesList,
                    button {
                        filter: blur(6px);
                    }

                    &:hover {

                        .header,
                        .slogan,
                        .servicesList,
                        button {
                            filter: blur(5px);
                        }
                    }
                }

                &.notAvailable {
                    button {
                        background-color: $color-grey;
                        cursor: default;
                    }
                }

                &.subscribed {
                    .slogan {
                        background-color: $color-green;
                        color: $color-light;
                    }

                    button {
                        background-color: $color-green;
                        color: $color-light;

                        &:hover {
                            background-color: $color-green-dark;
                        }
                    }
                }
            }
        }
    }
}