* {
    box-sizing: border-box;
    outline: none;
    border: none;
}

body {
    background-color: $color-firstMain;
    margin: 0;
    padding: 0;
    font-family: $font-text;
    overflow-x: hidden;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
    color: $color-light;
    min-width: $width-smallest;

    // SMALLEST RESOLUTION
    @media (max-width: $width-smallest) {
        & {
            overflow-x: auto;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-title;
}

h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 3rem;
    letter-spacing: 3px;
    margin: 30px 0;
    font-weight: bold;
}

strong {
    color: $color-secondMain;
}

#app.logged {
    main {
        /*min-height: calc(100vh - 62px - 30px);*/
        margin-left: $width-lateralMenu;
        padding: 0 40px 40px 40px;
    }

    footer {
        margin-left: $width-lateralMenu;
    }
}

#app:not(.logged) {
    main {
        /*min-height: calc(100vh - 62px - 110px);*/

        h1 {
            margin-top: 110px;
        }
    }
}

a {
    color: $color-secondMain;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

p {
    text-align: justify;
}

button.btn {
    padding: 10px 30px;
    margin: 40px auto;
    border-radius: 7px;
    cursor: pointer;
    display: block;
    color: $color-light;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: $font-text;

    &.thematized {
        background-color: $color-secondMain;
        color: $color-firstMain;
        padding: 15px 20px;
        font-size: 1rem;
        font-family: "font-title";
        text-transform: uppercase;
        margin: 0 auto;
        border-radius: 0;

        &:hover {
            background-color: $color-secondMain-dark;
        }
    }

    &.classic {
        background-color: $color-firstMain-light;
        border: 1px solid $color-firstMain-dark;

        &:hover {
            background-color: $color-firstMain-dark;
        }
    }

    &.success {
        background-color: $color-green;
        border: 1px solid $color-green-dark;

        &:hover {
            background-color: $color-green-dark;
        }
    }

    &.error {
        background-color: $color-red;
        border: 1px solid $color-red-dark;

        &:hover {
            background-color: $color-red-dark;
        }
    }

    &:disabled {
        background-color: gray;
        border: #666666;
        cursor: default;

        &:hover {
            background-color: gray;
        }
    }

    svg {
        font-size: 20px;
    }
}


table {
    border-collapse: collapse;
    text-align: center;

    thead {
        tr {
            th {
                font-weight: bold;
                padding: 3px;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 3px;
            }
        }
    }

    td,
    th {
        border: 1px solid black;
    }
}

.rdt_Table {

    .rdt_TableCell,
    .rdt_TableCol {
        border-right: 1px solid $color-firstMain-light;
        border-left: 1px solid $color-firstMain-light;
        padding: 10px;
    }

    .rdt_TableHeadRow {
        background-color: $color-firstMain-light;
        color: $color-light;

        .rdt_TableCol {
            font-size: 1.1rem;
            font-weight: bold;

            .rdt_TableCol_Sortable {
                justify-content: center;
            }
        }
    }

    .rdt_TableRow {
        background-color: $color-firstMain;

        &:not(:last-of-type) {
            border-bottom-color: rgba(255, 255, 255, 0.12);
        }

        .rdt_TableCell {
            font-size: 1rem;
            justify-content: center;
            color: $color-light;

            div[data-tag=allowRowEvents] {
                white-space: normal;
            }

            .newVersion {
                color: $color-secondMain;
            }

            strong {
                color: $color-yellow;
            }

            .won,
            .positive {
                color: $color-green;
            }

            .lost,
            .negative {
                color: $color-red;
            }

            .surrender {
                color: #b3b3b3;
            }
        }
    }
}

.rdt_Pagination {
    background-color: $color-firstMain-light !important;
    color: $color-light !important;

    svg {
        fill: $color-light;
    }
}

#loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: $color-firstMain-dark;
    opacity: 0.9;
    z-index: 9999;
    font-size: 2.5rem;
    color: $color-light;
    text-align: center;

    .imgContainer {
        position: absolute;
        top: calc(50vh - 150px);
        width: 100%;

        img {
            width: 150px;
        }
    }

    p {
        text-align: center;
        position: absolute;
        top: calc(50vh + 100px);
        margin: 0;
        width: 100%;
    }
}


#inConstruction {
    background-color: $color-firstMain;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;

    h1#logo {
        color: $color-secondMain;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 20vh;
        margin-bottom: 15vh;
        font-size: 6rem;

        .light {
            color: $color-light;
            background-color: #c91515;
            margin-right: 10px;
            padding: 10px;
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        img {
            width: 250px;
            margin-right: 5%;
        }

        .titles {
            h2 {
                color: $color-secondMain;
                font-size: 3rem;
                margin-bottom: 5vh;
            }

            h3 {
                color: $color-light;
                font-size: 2.2rem;
            }
        }
    }
}

#incomingFeature {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 90px auto;
    background: #273145;
    width: fit-content;
    color: $color-secondMain;
    padding: 50px 100px;
    clip-path: polygon(50px 0, 100% 0, calc(100% - 50px) 100%, 0 100%);

    img {
        width: 300px;
    }

    h2 {
        font-size: 5rem;
        text-transform: uppercase;
        margin-left: 50px;
    }
}


#noActiveSubscription {
    color: $color-light;
    font-size: 1.3rem;
    padding: 10px 0;
    text-align: center;
    background-color: $color-red;
}



.partOfPage {
    width: 60%;
    margin: auto;
}




.react-tooltip {
    z-index: 9999 !important;
}




footer {
    margin-top: 90px;
    padding: 20px;
    background-color: $color-firstMain;
    border-top: 1px solid $color-secondMain;

    .content {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: auto;
        gap: 15%;

        .column {
            h1 {
                font-size: 1.1rem;
                color: $color-secondMain;
            }

            .items {
                text-align: center;

                a {
                    h2 {
                        color: $color-light;
                        font-weight: normal;
                        font-size: 1rem;
                        font-family: "font-text";
                    }

                    &:hover {
                        h2 {
                            color: $color-secondMain;
                        }
                    }

                }
            }
        }
    }
}