#backTest {
    h1 {}

    .error {
        color: $color-secondMain;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
    }

    label[for=startPeriod],
    label[for=endPeriod],
    label[for=presetModel] {
        width: auto;
    }

    .parametersContainer {}

    .noBetsFound {
        text-align: center;
        font-size: 1.2rem;
    }

    .keyFiguresContainer {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;

        .keyFigure {
            flex: 1;
            max-width: 250px;
            min-width: 200px;
            background-color: $color-secondMain;
            color: white;
            font-family: $font-title;
            position: relative;
            box-shadow: 1px 1px 16px 1px #00000057;
            transition: all 0.5s;

            &:nth-child(1) {
                background-color: #fd5901;
            }

            &:nth-child(2) {
                background-color: #f78104;
            }

            &:nth-child(3) {
                background-color: #faab36;
            }

            &:nth-child(4) {
                background-color: #249ea0;
            }

            &:nth-child(5) {
                background-color: #008083;
            }

            &:nth-child(6) {
                background-color: #005f60;
            }

            &:hover {
                box-shadow: 1px 1px 16px 1px #0000009f;
                filter: brightness(0.9);
            }

            .container {
                display: flex;
                gap: 10px;
                justify-content: space-around;
                align-items: center;
                height: 100%;
                padding: 20px 5%;
                text-transform: uppercase;
                flex-direction: column;

                div {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .value {
                    font-size: 3rem;
                    font-weight: bold;

                    small {
                        font-size: 2rem;
                    }
                }

                .label {
                    flex: 1;
                    font-size: 1.7rem;

                    small {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }

    .pieChartsContainer {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-evenly;
        gap: 50px;
        margin-bottom: 40px;
        margin-top: 40px;

        // < MOBILE
        @media (max-width: $width-mobile) {
            & {
                flex-direction: column;
            }
        }

        .booksChart,
        .sportsRepartitionChart {
            flex: 1;
        }

        .booksChart {}

        .sportsRepartitionChart {}
    }
}