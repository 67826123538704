#myBets {
    .filtersContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .filter {
            margin: 10px 0;
            width: 33%;

            // DESKTOP
            @media (max-width: $width-desktop) {
                & {
                    width: 50%;
                }
            }

            // MOBILE
            @media (max-width: $width-mobile) {
                & {
                    width: 100%;
                }
            }

            label {
                font-weight: bold;
                margin-right: 10px;
            }

            input,
            select {
                font-size: 1.05rem;
                padding: 10px;
                margin: 0 5px;
                text-align: center;
                font-family: $font-text;
                border-width: 0;
                border-radius: 0;
                background-color: $color-firstMain-light;
                color: $color-light;
            }

            input[type=number] {
                width: 80px;
            }

            input:disabled {
                background-color: $color-firstMain-dark;
            }

            #sportsFilter,
            #booksFilter,
            #marketsFilter {
                width: 250px;
            }

            .formField {
                display: flex;
                margin: 0;
                justify-content: flex-start;

                label {
                    width: auto;
                }
            }

            &.timeSinceOpeningFilter,
            &.timeUntilClosingFilter {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;
                flex-wrap: wrap;
            }
        }
    }

    #downloadBets {
        float: right;
        position: relative;
        bottom: 10px;
        margin: -25px 0;
        cursor: pointer;
    }

    .rdt_Table {
        margin-top: 20px;

        .rdt_TableHeadRow {
            .rdt_TableCol {

                // Sport, Odd, Actions columns
                &:nth-child(1),
                &:nth-child(6),
                &:last-child {
                    flex-grow: 0.2;
                }

                // Match, Bet side columns
                &:nth-child(2),
                &:nth-child(3) {
                    flex-grow: 1.3;
                }
            }
        }

        .rdt_TableBody {
            background-color: $color-firstMain;

            .rdt_TableRow {
                .rdt_TableCell {
                    &:not(:nth-child(2)) {
                        text-align: center;
                    }

                    // Sport, Odd, Actions columns
                    &:nth-child(1),
                    &:nth-child(6),
                    &:last-child {
                        flex-grow: 0.2;

                        i {
                            color: $color-firstMain;
                        }
                    }

                    // Match, Bet side columns
                    &:nth-child(2),
                    &:nth-child(3) {
                        flex-grow: 1.3;
                        justify-content: flex-start;

                        strong {
                            color: $color-yellow;
                        }
                    }

                    .iconAndText {
                        svg {
                            font-size: 25px;
                            color: $color-light;
                        }

                        span {
                            position: relative;
                            bottom: 6px;
                        }
                    }

                    .CLV {

                        .positive,
                        .negative {
                            font-weight: bold;
                        }

                        small {
                            display: block;

                            &:first-child {
                                margin-bottom: -6px;
                            }

                            &:last-child {
                                margin-bottom: -2px;
                            }
                        }
                    }

                    .matchResult {
                        font-weight: bold;
                    }

                    .actions {
                        svg {
                            font-size: 27px;
                            cursor: pointer;

                            &:hover {
                                color: #33405e;
                            }
                        }
                    }
                }

                &.notTaken {
                    background-color: #00000050;
                }
            }
        }
    }
}