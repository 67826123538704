#app {
	#menu {
		#logo {
			color: $color-secondMain;
			text-transform: uppercase;
			font-weight: bold;

			.light {
				color: $color-firstMain;
				background-color: $color-secondMain;
			}
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&:not(.logged) #menu {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		background-color: $color-firstMain;
		position: fixed;
		width: 100%;
		min-width: $width-smallest;
		z-index: 9999;
		top: 0;
		border-bottom: 1px solid $color-secondMain;

		#logo {
			font-size: 2rem;
			display: flex;
			align-items: center;
			letter-spacing: 4px;
			margin: 0;
			height: 100%;

			// MOBILE
			@media (max-width: $width-mobile) {
				& {
					font-size: 1.6rem;
				}
			}

			.light {
				padding: 5px 8px;
				margin: 0 5px 0 15px;
			}
		}

		#itemsContainer {
			display: flex;
			flex: 1;
			justify-content: flex-end;
			margin-right: 10px;

			// MOBILE
			@media (max-width: $width-mobile) {
				& {
					flex-direction: column;
					align-items: flex-end;
				}
			}

			a {
				font-family: $font-title;
				text-transform: uppercase;
				letter-spacing: 1px;
				display: inline-block;
				font-size: 1.4rem;
				padding: 20px 20px;
				font-weight: 400;
				height: 100%;
				cursor: pointer;
				color: $color-light;

				// MOBILE
				@media (max-width: $width-mobile) {
					& {
						font-size: 1.2rem;
						padding: 15px 30px;
					}
				}

				&:hover {
					background-color: $color-secondMain;
					transition: 0.2s;
					color: $color-firstMain;
				}

				&.active {
					background-color: $color-secondMain;
					color: $color-firstMain;
				}

				&.favourite {
					background-color: $color-secondMain;
					color: $color-firstMain;

					&:hover {
						background-color: $color-secondMain-dark;
						transition: 0.2s;
					}
				}
			}

			#centralMenu {
				margin-right: 10%;

				// MOBILE
				@media (max-width: $width-mobile) {
					& {
						margin-right: 0;
					}
				}
			}

			#rightMenu {
				a {
					height: auto;
					padding: 5px 20px;
					margin-top: 14px;
					border: 2px solid $color-secondMain;

					// MOBILE
					@media (max-width: $width-mobile) {
						& {
							margin-top: 0;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}

	&.logged #menu {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		background-color: $color-firstMain;
		width: $width-lateralMenu;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		position: fixed;
		top: 0;
		left: 0;
		font-family: $font-title;
		border-right: 1px solid $color-secondMain;

		#logo {
			font-size: 2.5rem;
			letter-spacing: 3px;

			.light {
				padding: 3px 5px;
				margin-right: 3px;
			}
		}

		#itemsContainer {
			display: flex;
			flex-direction: column;
			align-items: stretch;

			a {
				color: $color-light;
				padding: 10px 0;

				&:hover {
					background-color: $color-secondMain;
					transition: 0.3s;
					color: $color-firstMain;
				}

				&.active {
					color: $color-firstMain;
					background-color: $color-secondMain;

					&:hover {
						color: $color-firstMain;
						background-color: $color-secondMain-dark;
					}
				}

				.item {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 10px 0;

                    &.unread-notification-wrapper {
                        position: relative;
                    }

					svg {
						display: block;
						font-size: 2rem;
						margin-bottom: 5px;
					}

					span {
						font-size: 0.8rem;
						text-transform: uppercase;
						text-align: center;

						&.unread-notification {
							position: absolute;
							top: -5px;
							right: 25px;
							background: #cc2121;
							color: #ebe9fc;
							border-radius: 50%;
							padding: 2px 6px;
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}
